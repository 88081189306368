@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Saira Condensed', sans-serif;
    min-height: 100vh;
    background-image: url(../images/bg-lines.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

h1, h2, h3, h4 {
    font-family: 'Averia Sans Libre', sans-serif;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 2.5rem;
}

h5 {
    font-size: 1.75rem;
}

#root {
    overflow: hidden;
}

.miniText {
    font-size: 1rem;
    font-weight: 400;
}

#site-header {
    width: 100%;
    position: fixed;
    z-index: 4;
    display: flex;
    justify-content: space-between;

    .site-title {
        // font-family: 'Averia Sans Libre', sans-serif;
        font-weight: bold;
        font-size: 1.5rem;
        display: flex;
        align-items: flex-start;
        padding-right: 3rem;
        padding-top: 3rem;
        transition: all 0.25s ease-in-out;

        .nav {
            // margin-top: -0.5rem;
            margin-right: 3rem;
            display: none;

            .nav-link {
                font-size: 1.2rem;
                font-weight: 400;
                color: var(--bs-secondary);
                padding: 5px 2rem;
                // padding: 2rem;

                &.active {
                    background-color: var(--bs-secondary);
                    color: var(--bs-primary);
                    // text-decoration: underline;
                }
            }
        }

        .ham-menu {
            width: 35px;
            margin-top:5px;
            transform: rotate(180deg);
        }
    }

    .site-logo {
        padding-left: 1rem;
        padding-top: 1rem;
        transition: padding 0.25s ease-in-out;

        img {
            width: 220px;
            transition: width 0.25s ease-in-out;
            padding: 10px;
        }
    }

    &.scroll {
        background-color: #fff;
        box-shadow: 0 0 20px -5px rgb(0 0 0 / 25%);
        
        .site-title { 
            align-items: center;
            padding-top: 0;
            font-size: 1.25rem;

            .nav-link {
                font-size: 1.1rem;
            }

            .ham-menu {
                width: 30px;
                margin-top: 0;
            }
        }
        
        .site-logo {
            padding-top: 0;
            padding-right: 0;

            img {
                width: 180px;
            }
        }
    }
}

#site-menu {
    background-color: var(--bs-primary);
    position: fixed;
    top: -100vh;
    z-index: 3;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 90px 10px 30px;
    transition: top 0.2s ease-out;
    
    &.open {
        top: 0;
    }

    &>div {
        align-self: flex-end;
        flex-basis: 45%;

        h6 {
            a {
                text-decoration: underline;
                color: #fff;
                display: inline-block;
                font-weight: normal;
                padding: 0.25rem 0;
                
                &:hover {
                    text-decoration: none;
                }
            }
        }

        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                a {
                    display: inline-block;
                    color: var(--bs-body-color);
                    text-decoration: none;
                    font-size: 32px;
                    font-weight: lighter;
                    padding: 0.25rem 0;
                    opacity: 0.9;
                    
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.main {
    min-height: 100vh;
    // background-image: url(../images/bg-lines.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.skewBG {
    background-color: var(--bs-secondary);
    color: var(--bs-white);
    padding: 8px 30px;
    transform: skewX(-30deg);
    
    span {
        transform: skewX(30deg);
        display: inline-block;
    }
    
    &.inverse {
        transform: skewX(30deg);
        
        span {
            transform: skewX(-30deg);
        }
    }

    &.miniText {
        padding: 4px 20px;
    }
}

a.skewBG {
    &:hover {
        border: 1px solid var(--bs-primary)!important;
        background-color: var(--bs-primary)!important;
    }
}

.dummyContent {
    opacity: 0.75;
    border: 1px dashed;
    border-radius: 10px;
    padding: 10px;
}

.subscribeNow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    // min-height: 50vh !important;
    background-color: var(--bs-light);

    .subscribeForm {

        label {
            display: block;
            font-weight: 600;
        }

        input {
            width: 100%;
        }
    }
}



.footer {
    justify-content: space-between;
    min-height: 70vh;
    .profInfo {
        text-align: end;

        .professor {
            width: 152px;
            height: 152px;
            border-radius: 20px;
            overflow: hidden;
            display: inline-block;
            
            img {
                width: 100%;
            }
        }
        p {
            margin-bottom: 0;
        }
    }   
    h2 {
        font-size: 32px;
    }
    .ftrLogo {
        width: 120px;
    }
    .ftrIcons {
        width: 42px;
    }
    .ftrActs {
        margin-left: 0.75rem;

        p {
            margin-bottom: 0;
        }
    }
    .ftrLinks {
        a {
            display: inline-block;
            margin-right: 1.5rem;
        }
    }
}


@media (min-width: 768px) {
    #site-header {
        .site-title {
            .nav {
                display: flex;
            }
        }
    }

    #site-menu {
        padding: 32vh 3rem;

        
        &>div {
            align-self: flex-start;
            flex-basis: 25%;
        }
    }
    .footer {
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
}
@media (min-width: 992px) {
    
}
// xl
@media (min-width: 1200px) {
    #site-menu {
        padding: 32vh 5rem;
    }
    h3 {
        font-size: 3rem;
    }
}
// xxl
@media (min-width: 1400px) {
    #site-menu {
        padding: 32vh 8rem;
    }
    h3 {
        font-size: 4rem;
    }
}
// xxxl
@media (min-width: 1900px) {
    #site-menu {
        padding: 32vh 15vw;
    }
}