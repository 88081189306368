.fullFold {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    min-height: 100vh;
}
.halfFold {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    min-height: 50vh;
}
::-webkit-scrollbar{
	height: 10px;              /* height of horizontal scrollbar ← You're missing this */
    width: 10px;               /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track-piece{
	background-color: #FFF;
}

::-webkit-scrollbar-thumb{
	background-color: #CBCBCB;
	outline: 2px solid #FFF;
	outline-offset: -2px;
	border: .1px solid #B7B7B7;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
	background-color: #909090;
}
.siteLoader {
    position: absolute;
    right: -100vw;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgb(255, 255, 255);
    transition: right 0.15s ease-in-out;

    &.show {
        right: 0vh;
    }

    img {
        width: 80px;
    }
}
.homeMain {
    
    text-align: center;

    .app--bgg {
        position: relative;
        .app--bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-weight: 300;
        font-size: 1rem;
        margin: 20px 0;
    }

    h6 {
        font-size: 20px;
    }
    
    .slideshow {
        margin: 0 auto;
        overflow: hidden;
        max-width: 600px;

        .slideshowSlider {
            white-space: nowrap;
            // transition: ease 1000ms;
         
            .slide {
              display: inline-block;
            //   height: 600px;
              width: 100%;
              border-radius: 40px;

              img {
                width: 100%;
              }
            }
        }

        .slideshowDots {
            text-align: center;
            
            .slideshowDot {
                display: inline-block;
                height: 10px;
                width: 10px;
                border-radius: 50%;
        
                cursor: pointer;
                margin: 15px 7px 0px;
        
                background-color: #c4c4c4;
                
                &.active {
                    background-color: var(--bs-primary);
                }
            }
        
        }
          
          
    }
      

    
}

.scrollAlt {
    position: absolute;
    bottom: 20px;
}
.pageMain,.contactMain {
    width: 80vw;
    text-align: center;

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-weight: 300;
        font-size: 1rem;
        margin: 20px 0;
    }
    
}
.contactMain {
    text-align: left;
}
.homeSecond {
    text-align: center;
    background-color: var(--bs-secondary);

    .card-body {
        min-height: 270px;
    }
}

.currentLiterature {
    min-height: 50vh;
    background-color: var(--bs-primary);

    .literatures {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &>div {
            display: flex;
            width: 150px;
            height: 150px;
            font-size: 24px;
            line-height: 1.25;
            background-color: var(--bs-secondary);
            color: var(--bs-light);
            border-radius: 2.5rem;
            border: 5px solid var(--bs-primary);
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 2rem;
            z-index: 2;
            
            &:first-child {
                margin-right: -1.75rem;    
                width: 135px;
                height: 135px;
                font-size: 22px;
                z-index: 1;
            }
            &:last-child {
                margin-left: -1.75rem;
                width: 135px;
                height: 135px;
                font-size: 22px;
                z-index: 0;
            }
        }
    }
}

#applications {
    min-height: calc(100vh - 60px);
    padding: 2.5rem 0;

    .home--app {
        .app--image {
            width: 100%;
            
            &.hide {
                opacity: 0;
                // transition: opacity 0.05s ease-in-out;
            }
            &.show {
                opacity: 1;
                transition: opacity 0.25s ease-in-out;
            }
        }
        .appActs {
            margin: 10px 0  10px 10px;
            span {
    
                &.slideActs {
                    display: inline-block;
                    cursor: pointer;
                }
            }
        }

        .app--content {
            &.hide {
                opacity: 0;
            }
            &.show {
                opacity: 1;
            }

            .app--desc {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
    }

    

}


#allApplications {
    .mainApps {
        width: 100%;
    }
    .scroller {
        display: flex;
        flex-wrap: wrap;
        &>div {
            width: 50%;
            img {
                width: 100px;
            }    
        }
        
    }
}




.text-block {
    margin: 0 auto;
}
// About
#vision {
    background-color: var(--bs-light);


    .vision-cards {
        // width: 80%;
        margin: 0 auto;
        img {
            width: 100px;
        }
    }
}

#impact {
    background-color: var(--bs-secondary);
    
    .scroller {
        display: flex;
        flex-wrap: wrap;
        &>div {
            width: 50%;
            img {
                width: 90px;
            }    
        }
    }
}
#publications {

    .pubPics {
        display: block;

        
        &>div {
            width:100%;
            .pubLinks {
                text-decoration: none;
                color: var(--bs-secondary);
                p {
                    margin-top: 2.5rem;
                    padding: 0 1rem;
                    transition: all 0.25s ease-in-out;
                    min-height:53px;
                }
                img {
                    height: 222px;
                    display: inline-block;
                    box-shadow: 5px 5px rgba(67, 67, 67, 0.16);
                    transition: box-shadow 0.25s ease-in-out;
                }
            }
            &:hover {
                img {
                    box-shadow: 10px 10px rgba(67, 67, 67, 0.46);
                }
                p {
                    color: var(--bs-danger);
                }
            }
        }

    }
}

#faqs {
    background-color: var(--bs-light);
}
#events {
    background-color: var(--bs-primary);
}
#newsroom {
    background-color: var(--bs-secondary);
}

.patentImg{
    img {
        width: 100%;
        margin-bottom: 1rem;
        // border: 1px solid #00000093;
        box-shadow: 0 10px 20px -20px #000;
    }
}

@media (min-width: 768px) {
    #publications {
        .pubPics {
            display: flex;
            justify-content: space-evenly;
            overflow-x: scroll;
            width: 100%;
            padding-bottom: 1rem;
            &>div {
                width: 28%;
                min-width: 28%;
            }
        }
    }
    #impact {
        .scroller {
            &>div {
                width: auto;
            }
        }
    }

    #allApplications {
        .scroller {
            &>div {
                width: auto;
                img {
                    width: 200px;
                }    
            }
            
        }
    }
    .patentImg {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        
        img {
            width: 32%;
        }
    }
}
@media (min-width: 992px) {
    .homeMain {
        max-width: 85vw;
        h1 {
            font-size: 3rem;
        }
        h2 {
            font-size: 1.75rem;
        }
    }
}

@media (min-width: 1200px) {

    #applications {
        .home--app {
            .app--image {
                width: 500px;
            }
        }
    }
    .homeMain {
        max-width: 70vw;
        h1 {
            // font-size: 5rem;
        }
        h2 {
            font-size: 1.75rem;
        }
    }

    #applications {
        .home--app {
            img {
                width: auto;
            }
        }
    }

}

@media (min-width: 1400px) {

}